<template>
  <div class="elite-tabs-wrapper-content">
    <div class="row mb-4">
      <div class="col-8">
        <h3 class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</h3>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openPurchasesInvoiceItemModal()"
          type="button"
          class="btn btn-sm btn-primary"
          v-if="purchasesInvoice.status === INVOICE_STATUS_DRAFT"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus-circle"></i>
          </span>
          {{ $t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE_ITEM") }}
        </base-button>
      </div>
    </div>

    <purchases-invoice-view-details-purchases-invoice-item-table
      :purchasesInvoice="purchasesInvoice"
      @onOpenPurchasesInvoiceItemModal="openPurchasesInvoiceItemModal"
    />

    <div class="total-wrapper" v-if="purchasesInvoice.pricing">
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesInvoice.pricing.subtotal) }}
          </dd>
        </dl>

        <dl class="row mb-0" v-if="purchasesInvoice.pricing.discounts.total">
          <dt class="col-sm-6">{{ $t("COMMON.DISCOUNTS") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesInvoice.pricing.discounts.total * -1) }}
          </dd>
          <dl
            class="row"
            v-for="(discount, index) in purchasesInvoice.pricing.discounts
              .details"
            v-bind:key="index"
          >
            <dt class="col-sm-6 text-muted">{{ discount.name }}</dt>
            <dd class="col-sm-6 text-right text-muted">
              {{ $formatCurrency(discount.amount) }}
            </dd>
          </dl>
        </dl>

        <dl class="row mb-0" v-if="purchasesInvoice.pricing.taxes.total">
          <dt class="col-sm-6">{{ $t("COMMON.TAXES") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesInvoice.pricing.taxes.total) }}
          </dd>
          <dl
            class="row"
            v-for="(tax, index) in purchasesInvoice.pricing.taxes.details"
            v-bind:key="index"
          >
            <dt class="col-sm-6 text-muted">{{ tax.name }}</dt>
            <dd class="col-sm-6 text-right text-muted">
              {{ $formatCurrency(tax.amount) }}
            </dd>
          </dl>
        </dl>

        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesInvoice.pricing.total) }}
          </dd>
        </dl>
      </div>
    </div>

    <!-- Modal add slot -->
    <purchases-invoice-view-details-purchases-invoice-item-form
      :purchasesInvoice="purchasesInvoice"
      :purchasesInvoiceItem="purchasesInvoiceItem"
      :showModal="showModal"
      :formErrors="itemsFormError"
      @onClosePurchasesInvoiceItemModal="closePurchasesInvoiceItemModal"
      @onSubmitPurchasesInvoiceItemForm="handlePurchasesInvoiceItemFormSubmit"
      @onDeletePurchasesInvoiceItem="deletePurchasesInvoiceItem"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
} from "@/constants/invoices";
import PurchasesInvoiceViewDetailsPurchasesInvoiceItemForm from "./PurchasesInvoiceViewDetailsPurchasesInvoiceItemForm.vue";
import PurchasesInvoiceViewDetailsPurchasesInvoiceItemTable from "./PurchasesInvoiceViewDetailsPurchasesInvoiceItemTable.vue";
import defaultPurchasesInvoiceItem from "../defaultPurchasesInvoiceItem";

export default {
  name: "purchases-invoice-view-details",

  components: {
    PurchasesInvoiceViewDetailsPurchasesInvoiceItemForm,
    PurchasesInvoiceViewDetailsPurchasesInvoiceItemTable,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [],

  props: ["purchasesInvoice"],

  data() {
    return {
      purchasesInvoiceItem: cloneDeep(defaultPurchasesInvoiceItem),
      loading: false,
      showModal: false,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      itemsFormError: null,
    };
  },

  computed: {},

  methods: {
    openPurchasesInvoiceItemModal(purchasesInvoiceItem = null) {
      if (!purchasesInvoiceItem) {
        this.purchasesInvoiceItem = cloneDeep(defaultPurchasesInvoiceItem);
      } else {
        this.purchasesInvoiceItem = cloneDeep(purchasesInvoiceItem);
      }
      this.showModal = true;
    },

    closePurchasesInvoiceItemModal() {
      this.showModal = false;
      this.loading = false;
    },

    handlePurchasesInvoiceItemFormSubmit(purchasesInvoiceItemData) {
      if (!purchasesInvoiceItemData.id) {
        this.addPurchasesInvoiceItem(purchasesInvoiceItemData);
      } else {
        this.editPurchasesInvoiceItem(purchasesInvoiceItemData);
      }
    },

    async addPurchasesInvoiceItem(purchasesInvoiceItemData) {
      this.loading = true;
      try {
        purchasesInvoiceItemData.purchasesInvoice.id = this.purchasesInvoice.id;

        await this.$store.dispatch(
          "purchasesInvoiceItems/add",
          purchasesInvoiceItemData
        );
        this.$emit("purchasesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("PURCHASES_INVOICES.PURCHASES_INVOICE_ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async editPurchasesInvoiceItem(purchasesInvoiceItemData) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "purchasesInvoiceItems/update",
          purchasesInvoiceItemData
        );
        this.$emit("purchasesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("PURCHASES_INVOICES.PURCHASES_INVOICE_ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async deletePurchasesInvoiceItem(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("purchasesInvoiceItems/destroy", id);
        this.$emit("purchasesInvoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("PURCHASES_INVOICES.PURCHASES_INVOICE_ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
